<template>
  <div>
    <HeaderComposant />
    <router-view />
    <FooterComposant />
  </div>
</template>

<script setup>
import { ref, onMounted, provide } from 'vue';
import { useI18n } from 'vue-i18n';

import HeaderComposant from '@/components/header/headerComposant.vue';
import FooterComposant from '@/components/footer/footerComposant.vue';

const theme = ref(localStorage.getItem('theme'));
const language = ref(localStorage.getItem('language'));

const { locale } = useI18n();

onMounted(() => {
  if (!localStorage.getItem('theme')) {
    localStorage.setItem('theme', 'dark');
  }
  
  // S'assurer que la langue est bien 'fr' par défaut, même si localStorage est vide
  if (!localStorage.getItem('language')) {
    localStorage.setItem('language', 'fr');
  }
  
  updateTheme();
  locale.value = language.value;
  
  // Mise à jour de la couleur de la barre de statut pour iOS
  updateStatusBarColor();
});

function toggleTheme() {
  theme.value = theme.value === 'dark' ? 'light' : 'dark';
  localStorage.setItem('theme', theme.value);
  updateTheme();
  updateStatusBarColor(); // Mettre à jour la couleur de la barre de statut lors du changement de thème
}

function updateTheme() {
  document.documentElement.classList.toggle('dark', theme.value === 'dark');
}

function updateStatusBarColor() {
  // Mise à jour des méta-tags pour la barre de statut iOS
  const metaThemeColor = document.querySelector('meta[name=theme-color]');
  if (metaThemeColor) {
    // Utiliser les couleurs de fond correspondant à votre design
    metaThemeColor.setAttribute('content', theme.value === 'dark' ? '#0f172a' : '#ffffff');
  } else {
    // Créer la balise si elle n'existe pas
    const meta = document.createElement('meta');
    meta.name = 'theme-color';
    meta.content = theme.value === 'dark' ? '#0f172a' : '#ffffff';
    document.head.appendChild(meta);
  }
  
  // Configurer apple-mobile-web-app-status-bar-style pour iOS
  let appleStatusBar = document.querySelector('meta[name=apple-mobile-web-app-status-bar-style]');
  if (!appleStatusBar) {
    appleStatusBar = document.createElement('meta');
    appleStatusBar.name = 'apple-mobile-web-app-status-bar-style';
    document.head.appendChild(appleStatusBar);
  }
  
  // Définir sur "black-translucent" pour le mode sombre et "default" pour le mode clair
  appleStatusBar.content = theme.value === 'dark' ? 'black-translucent' : 'default';
  
  // S'assurer que l'app est en mode "standalone" pour iOS
  let appleWebApp = document.querySelector('meta[name=apple-mobile-web-app-capable]');
  if (!appleWebApp) {
    appleWebApp = document.createElement('meta');
    appleWebApp.name = 'apple-mobile-web-app-capable';
    appleWebApp.content = 'yes';
    document.head.appendChild(appleWebApp);
  }
}

function setLanguage(lang) {
  language.value = lang;
  locale.value = lang;
  localStorage.setItem('language', lang);
}

provide('theme', theme);
provide('toggleTheme', toggleTheme);
provide('language', language);
provide('setLanguage', setLanguage);
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  background-color: #0D0D0DFF;
  color: #1f2937;
  transition: background-color 0.3s, color 0.3s;
}

html.dark body {
  background-color: #0D0D0DFF;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #0D0D0DFF;
}

::-webkit-scrollbar-thumb {
  background: #0D0D0DFF;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0D0D0DFF;
}

html.dark ::-webkit-scrollbar-track {
  background: #0D0D0DFF;
}

html.dark ::-webkit-scrollbar-thumb {
  background: #374151;
}

html.dark ::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

@viewport {
  width: device-width;
  initial-scale: 1;
}
</style>