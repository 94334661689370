import { createRouter, createWebHistory } from 'vue-router'
import i18n from '@/i18n'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('@/views/Welcome.vue'),
    meta: { titleKey: 'meta.welcome' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Projects.vue'),
    meta: { titleKey: 'meta.projects' },
  },
  {
    path: '/skills',
    name: 'Competences',
    component: () => import('@/views/Competences.vue'),
    meta: { titleKey: 'meta.skills' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: { titleKey: 'meta.contact' },
  },
  {
    path: '/learning',
    name: 'Learning',
    component: () => import('@/views/Learning.vue'),
    meta: { titleKey: 'meta.learning' },
  },
  
  // Examens et projets (regroupés)
  {
    path: '/examens',
    redirect: '/projects',
    children: [
      {
        path: 'gsb-web',
        name: 'GSB-WEB',
        component: () => import('@/views/GSB-WEB.vue'),
        meta: { titleKey: 'meta.gsb-web' },
      },
      {
        path: 'gsb-vb',
        name: 'GSB-VB',
        component: () => import('@/views/GSB-VB.vue'),
        meta: { titleKey: 'meta.gsb-vb' },
      },
      {
        path: 'nutsbetting',
        name: 'NUTSBETTING',
        component: () => import('@/views/NUTSBETTING.vue'),
        meta: { titleKey: 'meta.nutsbetting' },
      },
      {
        path: 'betcloud',
        name: 'BETCLOUD',
        component: () => import('@/views/BETCLOUD.vue'),
        meta: { titleKey: 'meta.betcloud' },
      },
      {
        path: 'oxyledger-wordpress',
        name: 'OxyWordPress',
        component: () => import('@/views/OxyWordPress.vue'),
        meta: { titleKey: 'meta.oxyledger-wordpress' },
      },
      {
        path: 'oxyledger-module',
        name: 'OxyModule',
        component: () => import('@/views/OxyModule.vue'),
        meta: { titleKey: 'meta.oxyledger-module' },
      }
    ]
  },
  
  // Gestion des erreurs
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { titleKey: 'meta.notFound' },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.titleKey && i18n.global.te(to.meta.titleKey)) {
    document.title = i18n.global.t(to.meta.titleKey);
  } else {
    document.title = 'Thomas';
  }
  next();
});


export default router;