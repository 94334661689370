<template>
  <footer class="bg-[#0D0D0DFF] border-t border-sky-700 shadow-md">
    <div class="w-full mx-auto max-w-screen-xl p-6 md:flex md:items-center md:justify-between">
      <div class="flex flex-col text-center md:text-left mb-4 md:mb-0">
        <span class="text-sm text-white mb-2">
          © {{ new Date().getFullYear() }} <a href="/" class="hover:text-sky-400 transition-colors duration-300">STINDEL
            Thomas</a>
        </span>
        <span class="text-xs text-gray-400">
          {{ $t('footer.rights') }}
        </span>
        <div class="flex justify-center md:justify-start">
          <span
            class="w-auto md:w-[33%] mt-3 text-xs bg-gray-800 px-3 py-1 rounded-full text-sky-300 border border-sky-700 shadow-inner group-hover:bg-sky-900 transition-colors duration-300">{{
              web_version }}</span>
        </div>
      </div>

      <div class="flex flex-col items-center md:items-end">
        <ul class="flex flex-wrap items-center justify-center md:justify-end text-sm font-medium text-gray-300 mb-3">
          <li>
            <RouterLink to="/projects"
              class="hover:text-sky-400 transition-colors duration-300 me-4 md:me-6 flex items-center"
              :class="{ 'text-sky-400 font-bold': $route.path === '/projects' }">
              {{ $t('footer.projects') }}
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/skills"
              class="hover:text-sky-400 transition-colors duration-300 me-4 md:me-6 flex items-center"
              :class="{ 'text-sky-400 font-bold': $route.path === '/skills' }">
              {{ $t('footer.skills') }}
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/contact" class="hover:text-sky-400 transition-colors duration-300 flex items-center"
              :class="{ 'text-sky-400 font-bold': $route.path === '/contact' }">
              {{ $t('footer.contact') }}
            </RouterLink>
          </li>
        </ul>

        <div class="flex items-center space-x-4">
          <a href="mailto:thomas29stdl@gmail.com"
            class="text-xs text-black p-1.5 rounded-xl rotate-1 hover:-rotate-1 duration-500 bg-white flex items-center">
            <svg class="w-3.5 h-3.5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            Email
          </a>
          <a href="https://www.linkedin.com/in/thomas-stindel-703bb628b/" target="_blank" rel="noopener noreferrer"
            class="text-xs text-white p-1.5 rounded-xl rotate-1 hover:-rotate-1 duration-500 bg-blue-600 flex items-center">
            <svg class="w-3.5 h-3.5 mr-1.5" viewBox="0 0 24 24" fill="currentColor">
              <path
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
            LinkedIn
          </a>
          <a href="https://github.com/Swellowwh" target="_blank" rel="noopener noreferrer"
            class="text-xs text-white p-1.5 rounded-xl rotate-1 hover:-rotate-1 duration-500 bg-black flex items-center">
            <svg class="w-3.5 h-3.5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path
                d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22">
              </path>
            </svg>
            GitHub
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { RouterLink, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { web_version, version_date } from '../../../version.js';

const $route = useRoute();
const { t } = useI18n();
</script>