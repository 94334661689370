const fr = {
    // 1. Éléments d'interface communs (header, footer)
    header: {
        "projects": "Projets",
        "skills": "Compétences",
        "reviews": "Examens",
        "contact": "Contact",
        "update_info": "Nouvelle version de mon site internet",
        subMenu: {
            "gsb_web": {
                "title": "Gestion de frais",
                "description": "Projet école - Application web"
            },
            "gsb_vb": {
                "title": "Gestion de visite",
                "description": "Projet école - Application lourde"
            },
            "nutsbetting": {
                "title": "Outil de gestion de bankroll et statistiques",
                "description": "Application web"
            },
            "betcloud": {
                "title": "Site e-commerce de conseil en paris sportifs",
                "description": "Plateforme e-commerce"
            },
            "oxyledger_web": {
                "title": "Site vitrine de l'entreprise Oxyledger",
                "description": "Site vitrine"
            },
            "oxyledger_module": {
                "title": "Outil de schématisation de salle d'opération",
                "description": "Application web"
            }
        },
    },
    footer: {
        "rights": "Tous droits réservés.",
        "projects": "Projets",
        "skills": "Compétences",
        "contact": "Contact",
    },

    // 2. Métadonnées et routeur
    meta: {
        "welcome": "Portfolio - Thomas",
        "projects": "Projects - Thomas",
        "skills": "Skills - Thomas",
        "contact": "Contact - Thomas",
        "learning": "Learn - Thomas",
        "reviews": "Reviews - Thomas",
        "notFound": "Page Not Found - Thomas",
        "gsb-web": "GSB Web Project - Thomas",
        "gsb-vb": "GSB Visual Basic Project - Thomas",
        "nutsbetting": "NutsBetting Project - Thomas",
        "betcloud": "BetCloud Project - Thomas",
        "oxyledger-wordpress": "Oxyledger WordPress Project - Thomas",
        "oxyledger-module": "Oxyledger Module - Thomas"
    },
    notFound: {
        "message": "Page non trouvée",
        "description": "Désolé, nous n\"avons pas pu trouver la page que vous recherchez.",
        "backToHome": "Retour à l\"accueil",
    },

    // 3. Pages spécifiques
    // 3.1 Page d'accueil
    home: {
        title: {
            "first": "Concevoir aujourd'hui",
            "second": "les solutions de demain",
            "introduction": "Étudiant en développement logiciel et auto-entrepreneur, je combine apprentissage académique et expérience professionnelle pour concevoir des solutions digitales modernes. À travers ce portfolio, découvrez mon engagement pour l'excellence, ma créativité, et ma capacité à transformer vos idées en projets aboutis, alliant performance et élégance.",
            "alternance": "En recherche active : Contrat d'alternance sur l'axe Brest-Rennes",
        },
        aboutMe: {
            "name": "STINDEL Thomas",
            "about_one": "Passionné par le développement logiciel et étudiant en informatique, je m'efforce chaque jour de transformer mes idées en réalité.",
            "about_two": "Mon parcours m'a permis de développer des compétences solides en programmation et en conception de solutions digitales innovantes.",
            "button": "Développeur logiciel",
        },
        project: {
            "postTitle": "Découvrez mes dernières réalisations",
            "title": "Découvrez mes projets",
            "description": "Je me concentre sur la création de solutions innovantes où la technologie et la créativité se rencontrent pour offrir des expériences utilisateur exceptionnelles et résoudre des problèmes concrets.",
            "button": "Découvrir mes projets",
        }
    },

    // 3.2 Page projets
    projects: {
        title: {
            "first": "Liste de tous mes",
            "second": "projets réunis",
            "description": "Découvrez mon parcours à travers mes expériences professionnelles et personnelles.",
        },
        table: {
            title: {
                "project": "Projet",
                "tech": "Technologies utilisées",
                "contributor": "Contributeur",
                "explore": "Explorer",
            },
            table_data: {
                "0": {
                    name: 'NutsBetting',
                    category: 'Outil de gestion de bankroll et statistiques',
                },
                "1": {
                    name: "Outil de schématisation de salle d'opération",
                    category: 'Application web',
                },
                "2": {
                    name: 'Gestion de frais',
                    category: 'Projet école - Application web',
                },
                "3": {
                    name: 'Gestion de visite',
                    category: 'Projet école - Application lourde',
                },
                "4": {
                    name: "Site vitrine de l'entreprise Oxyledger",
                    category: 'Site vitrine',
                },
                "5": {
                    name: 'Site e-commerce de conseil en paris sportifs',
                    category: 'Plateforme e-commerce',
                }
            },
            button: "Explorer le projet"
        },
        contact: {
            title: "Contactez-moi",
            description: "Vous avez un projet en tête ? Je suis à votre disposition pour discuter de sa réalisation.",
            button: "Me contacter par email",
        },
    },

    // 3.3 Page compétences
    skills: {
        title: {
            "discover": "Découvrez ",
            "skills": "mes compétences ",
            "expertise": "et mon savoir-faire"
        },
        "description": "Découvrez l\"ensemble de mes compétences techniques et créatives, acquises au fil de mes expériences professionnelles et académiques. De la conception d\"interfaces modernes à l\"optimisation des performances backend, en passant par l\"intégration de solutions innovantes, chaque technologie que je maîtrise me permet de transformer des idées en projets concrets. Faites défiler pour explorer mon savoir-faire et mes pratiques.",
        categories: {
            "frontendDev": "Développement Frontend",
            "backendDev": "Développement Backend",
            "designUX": "Design et Expérience Utilisateur",
            "database": "Base de Données et Stockage",
            "systems": "Systèmes et Hébergement",
            "webMobileDev": "Développement Web & Mobile",
            "cssFramework": "Framework CSS Moderne",
            "stateManagement": "Gestion de Statut Global",
            "wordpressDev": "Développement WordPress",
            "apiTesting": "API Testing & Development",
            "webServer": "Serveur Web et Hébergement",
            "webSecurity": "Sécurité et Performance Web",
            "vsCode": "Environnement de Développement",
            "typescript": "Développement avec TypeScript",
            "paymentIntegration": "Intégration de paiements en ligne",
            "projectManagement": "Gestion de projet avec ClickUp"
        },
        descriptions: {
            "frontendVue": "Maîtrise du développement d\"interfaces dynamiques et performantes avec Vue.js et React.",
            "backendLaravel": "Création d\"architectures robustes avec Laravel et Node.js.",
            "designFigma": "Conception d\"interfaces intuitives avec Figma et Adobe XD.",
            "databaseMySQL": "Optimisation et gestion des bases de données MySQL et PostgreSQL.",
            "systemsGitLab": "Déploiement et gestion des serveurs avec GitLab CI/CD et Docker.",
            "reactNative": "Développement d\"applications mobiles performantes avec React Native.",
            "tailwindCSS": "Développement rapide et flexible avec TailwindCSS et Bootstrap.",
            "pinia": "Gestion avancée de l\"état global avec Pinia et Redux.",
            "wordpress": "Création de sites dynamiques et performants avec WordPress et Elementor.",
            "java": "Développement robuste et évolutif avec Java et Spring Boot.",
            "bootstrap": "Stylisation rapide et efficace avec Bootstrap.",
            "postman": "Automatisation et tests avancés d\"API avec Postman et GraphQL.",
            "apache": "Hébergement et gestion d\"applications avec Apache et Nginx.",
            "cloudflare": "Protection DDoS et optimisation des performances web avec Cloudflare.",
            "vsCode": "Configuration avancée et optimisation de l\"environnement de développement avec VS Code.",
            "typescript": "Développement sécurisé et structuré avec TypeScript sur Vue.js et Node.js.",
            "paymentIntegration": "Intégration de solutions de paiement sécurisées avec Stripe et PayPal.",
            "projectManagement": "Suivi et organisation de tâches avec ClickUp pour une gestion de projet efficace."
        },
        "skillLevel": "Bonne maîtrise et application efficace"
    },

    // 3.4 Page contact
    contact: {
        "title": "Restons en contact !",
        "description": "Si vous souhaitez me contacter pour des opportunités professionnelles ou des collaborations, vous pouvez me trouver sur LinkedIn et GitHub. N\"hésitez pas à me contacter via ces plateformes."
    },

    // 3.5 Page spécifique projects
    specific_projects: {
        gsb_web: {
            section_1: {
                title: "Gestion des frais professionnels simplifiée",
                description: "Une application web moderne permettant aux visiteurs médicaux de gérer efficacement leurs notes de frais professionnels. Suivez et obtenez le remboursement de vos dépenses d'hébergement, repas et déplacements kilométriques en toute simplicité.",
            },
            section_2: {
                title: "Sous le capot",
            },
            section_3: {
                title: "Solution complète de gestion de frais",
                description: "Notre application facilite la gestion des notes de frais pour les professionnels de la santé en déplacement, avec un système complet pour la saisie, validation et remboursement des dépenses professionnelles.",
                subdescription: {
                    about_one: {
                        title: "Pour les visiteurs médicaux",
                        description: "Soumettez facilement vos dépenses d'hébergement, repas et frais kilométriques avec justificatifs depuis n'importe quel appareil et suivez l'état de vos remboursements."
                    },
                    about_two: {
                        title: "Pour les comptables",
                        description: "Accédez à un tableau de bord dédié pour examiner, valider et traiter les demandes de remboursement de manière efficace et transparente."
                    },
                    about_three: {
                        title: "Pour les administrateurs",
                        description: "Gérez les comptes utilisateurs, recrutez de nouveaux employés parmi les comptes créés et supervisez l'ensemble du processus de remboursement."
                    },
                },
            },
            section_4: {
                title: "À propos du projet",
                description: "Une application de gestion des rapports de visite pour les délégués médicaux de GSB, permettant le suivi des activités et l'optimisation des processus métiers.",
                button: "Explorer le code sur GitHub",
            },
        },

        gsb_vb: {
            section_1: {
                title: "Gestion des rapports médicaux",
                description: "Une application permettant aux visiteurs médicaux de créer, modifier et supprimer des rapports de visites patients, avec une interface adaptée aux différents types d'utilisateurs.",
            },
            section_2: {
                title: "La mécanique interne",
            },
            section_3: {
                title: "Gestion avancée des rapports médicaux",
                description: "Le projet GSB offre une application développée sous Visual Studio permettant aux visiteurs médicaux de créer et gérer leurs rapports de visites, avec une interface intuitive et des fonctionnalités adaptées au secteur pharmaceutique.",
                subDescription: {
                    one: "Interface Windows Forms Application développée avec Visual Basic et Windows Forms offrant une expérience utilisateur fluide et intuitive pour les visiteurs médicaux, avec un design ergonomique adapté aux besoins du secteur pharmaceutique.",
                    two: "Gestion complète des rapports Système permettant la création, modification, consultation et suppression des rapports de visites, avec possibilité d'ajouter des notes détaillées et de suivre l'historique des interactions avec chaque médecin.",
                    three: "Base de données sécurisée Architecture client lourd connectée à une base de données robuste, assurant la persistance des informations et permettant des recherches avancées par date, praticien ou type de visite.",
                }
            },
        },

        nutsbetting: {
            section_1: {
                subtitle: "Service de statistiques et de gestion",
                title: "L'outil NutsBetting",
                description: "Une application moderne permettant aux parieurs sportifs de gérer efficacement leurs paris et d'analyser leurs performances grâce à des statistiques détaillées. Développée intégralement par mes soins, cette solution représente un an et demi de travail alliant apprentissage technique et développement continu.",
            },
            section_2: {
                title: "L'anatomie du projet",
            },
            section_3: {
                subtitle: "Interface intuitive",
                title: "L'art de l'expérience utilisateur",
                description: "Notre approche centrée sur l'utilisateur combine design élégant et fonctionnalités accessibles. Chaque élément est pensé pour offrir une navigation fluide et instinctive, réduisant la courbe d'apprentissage et maximisant l'efficacité. Des couleurs soigneusement sélectionnées aux parcours utilisateurs optimisés, nous créons des interfaces qui séduisent autant qu'elles simplifient.",
            },
            section_4: {
                subtitle: "CI/CD GitLab",
                title: "Un workflow optimisé",
                description: {
                    one: "Le projet utilise un système de versionnement rigoureux avec documentation des modifications et tags sémantiques (v1.0.0, v1.1.0) pour identifier clairement les versions. Les branches de développement isolent les nouvelles fonctionnalités jusqu'à leur validation, garantissant la stabilité du code en production.",
                    two: "Ce workflow inclut la gestion des dépendances et des changelogs détaillés pour chaque release. L'intégration au pipeline CI/CD permet de déclencher des tests automatisés à chaque commit et d'activer des déploiements spécifiques via les tags de version, assurant ainsi qualité et fiabilité.",
                },
            },
            section_5: {
                title: "À propos de NutsBetting",
                description: "Une application complète dédiée aux parieurs sportifs, offrant des outils d'analyse statistique et de gestion de paris. Développée avec passion, elle permet d'optimiser vos stratégies et de suivre vos performances en temps réel.",
                button: "Découvrir NutsBetting.com",
            },
        },

        betcloud: {
            section_1: {
                subtitle: "Service de paris sportifs",
                title: "BetCloud E-Commerce",
                description: "Cette plateforme WordPress a été conçue pour offrir des conseils en paris sportifs. L'objectif a intégré un système d'analyses sportives et de prédictions pour aider les utilisateurs à optimiser leurs paris. Le projet a développé un modèle d'abonnements avec WooCommerce permettant de gérer différents niveaux d'accès aux recommandations et au contenu premium.",
            },
            section_2: {
                description_one: "Comptes créés au total",
                description_two: "Abonnements qui ont été actifs",
                description_three: "Temps de développement écoulé",
                time: "mois",
            },
            section_3: {
                title: "En coulisses",
            },
            section_4: {
                subtitle: "Profondeur",
                title: "Au coeur de BetCloud",
                description: "Dans l'objectif d'approfondir mes connaissances et de développer mes compétences, ce projet m'a permis de dépasser mes limites en créant une auto-entreprise. J'ai conçu un site web de A à Z sans expertise préalable dans le domaine, relevant ainsi un défi à la fois technique et entrepreneurial !",
                subDescription: {
                    one: {
                        name: "Abonnements personnalisés",
                        description: "Plateforme WordPress optimisée pour gérer différents types d'abonnements aux conseils en paris sportifs. Chaque utilisateur accède à un tableau de bord personnalisé selon son forfait.",
                    },
                    two: {
                        name: "Paiements sécurisés",
                        description: "Infrastructure de paiement robuste intégrant plusieurs passerelles pour des transactions fluides et sécurisées. Gestion automatisée des renouvellements d'abonnements.",
                    },
                },
            },
            section_5: {
                title: "BetCloud évolue vers NutsBetting",
                description_one: "Je suis ravi de vous annoncer que mon projet BetCloud n'est plus en activité et a été remplacé par NutsBetting. Cette transition marque l'aboutissement d'une superbe expérience de développement qui m'a permis d'affiner ma vision et d'améliorer considérablement mon offre.",
                description_two: "NutsBetting restera en production à l'avenir, bénéficiant de toutes les leçons que j'ai apprises pendant le développement de BetCloud. NutsBetting est une application complète que j'ai créée pour les parieurs sportifs, offrant des outils d'analyse statistique et de gestion de paris avancés. Développée avec passion, elle permet d'optimiser vos stratégies et de suivre vos performances en temps réel.",
                button: "Découvrir l'application web",
            },
        },

        oxyledger_web: {
            section_1: {
                subtitle: "Stagiaire chez Oxyledger",
                title: "Site vitrine",
                description: "Oxyledger développe des solutions innovantes pour assurer la traçabilité des dispositifs médicaux dans les établissements de santé. Grâce à ses technologies avancées, l'entreprise facilite la gestion des implants et dispositifs médicaux, garantissant sécurité et conformité. Découvrez une plateforme intuitive qui optimise les processus hospitaliers et améliore la prise en charge des patients.",
            },
            section_2: {
                title: "L'anatomie du projet",
            },
            section_3: {
                subtitle: "Design sur mesure",
                title: "Une identité visuelle cohérente",
                description: "J'ai développé une interface qui reflète parfaitement l'identité de l'entreprise. Chaque élément graphique a été méticuleusement conçu pour s'aligner avec ses valeurs et sa vision. Ma démarche a privilégié l'équilibre entre esthétique attrayante et fonctionnalité intuitive, créant ainsi une expérience utilisateur distinctive et mémorable. Les retours positifs des utilisateurs ont confirmé la pertinence de mes choix de design et la valeur ajoutée pour la marque.",
            },
            section_4: {
                title: "Projet Oxyledger",
                description: "Ce projet WordPress est entièrement fonctionnel et accessible en ligne. Vous pouvez dès maintenant visiter le site Oxyledger.com pour explorer l'interface que j'ai développée de A à Z. L'architecture personnalisée et les fonctionnalités sur mesure sont pleinement opérationnelles, offrant une expérience utilisateur optimale. N'hésitez pas à parcourir le site pour découvrir mon travail en situation réelle.",
                button: "Voir le site en action",
            },
        },

        oxyledger_module: {
            section_1: {
                subtitle: "Stagiaire chez Oxyledger",
                title: "Création d'un outil de dessin",
                description: {
                    one: "Oxyledger développe des solutions innovantes pour assurer la traçabilité des dispositifs médicaux dans les établissements de santé. Grâce à ses technologies avancées, l'entreprise facilite la gestion des implants et dispositifs médicaux, garantissant sécurité et conformité.",
                    two: "Lors de mon stage chez Oxyledger, j'ai eu l'opportunité de concevoir et de développer un outil innovant permettant de modéliser les salles d'opération avant intervention. Cet outil, directement relié à la fiche du patient, facilite l'organisation et l'optimisation de la salle en fonction des besoins spécifiques du chirurgien. Il vise à garantir une préparation efficace et sécurisée, améliorant ainsi le processus opératoire.",
                },
            },
            section_2: {
                title: "L'anatomie du projet",
            },
            section_3: {
                subtitle: "Place à l'imagination",
                title: "Un outil de schématisation sur mesure",
                description: {
                    one: "J'ai développé un outil de schématisation sur mesure répondant aux contraintes et besoins définis par l'entreprise avant mon stage. Conçu pour optimiser l'organisation des salles d'opération en fonction des fiches patients, cet outil intuitif facilite la préparation des interventions chirurgicales et améliore l'efficacité du personnel médical. Chaque élément graphique et fonctionnel a été pensé pour s'aligner avec l'identité de l'entreprise, tout en offrant une expérience utilisateur fluide et ergonomique.",
                    two: "Ce projet, entièrement réalisé par mes soins, est conçu pour évoluer et s'adapter aux futurs besoins de l'entreprise. Il représente une base solide pouvant être enrichie avec de nouvelles fonctionnalités afin d'optimiser encore davantage l'organisation des espaces médicaux.",
                },
            },
            section_4: {
                title: "Module pour établissements médicaux",
                description: "J'ai conçu et développé un module personnalisé destiné aux établissements médicaux. Pour des raisons de confidentialité et de sensibilité des données, l'accès à ce projet est restreint. Ce système permet une gestion optimisée des processus internes et offre une interface adaptée aux besoins spécifiques du personnel médical. L'architecture a été pensée pour garantir à la fois sécurité, confidentialité et facilité d'utilisation dans un environnement professionnel exigeant.",
            },
        },
    },
};

export default fr;