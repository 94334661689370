<template>
  <header class="fixed top-0 left-0 w-full bg-transparent z-50" :class="{ 'backdrop-blur-md': scrolled }">

    <div v-if="!bannerClosed"
      class="p-3 sm:p-4 bg-[#0D0D0DFF] rounded flex flex-wrap items-center justify-center sm:gap-x-6 relative shadow-md overflow-hidden">
      <div class="text-xs sm:text-sm text-white flex items-center max-w-[calc(100%-30px)] sm:max-w-none">
        <a href="#" class="flex items-center group transition-all duration-300 hover:scale-105">
          <div class="flex items-center space-x-1 sm:space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 sm:h-5 sm:w-5 text-sky-400 animate-pulse mr-1 sm:mr-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <p class="mr-1 sm:mr-2 text-xs sm:text-sm">{{ $t('header.update_info') }}</p>
            <span
              class="bg-gray-800 py-0.5 sm:py-1 px-2 sm:px-3 rounded-full text-sky-300 text-[10px] sm:text-xs border border-sky-700 shadow-inner group-hover:bg-sky-900 transition-colors duration-300">{{
                web_version }}</span>
          </div>
        </a>
      </div>
      <button type="button" @click="closeBanner"
        class="absolute right-2 top-2 sm:right-4 p-2 sm:p-3 focus-visible:outline-offset-[-4px] text-gray-400 hover:text-white transition-colors duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
      <div class="gradient-animation absolute bottom-0 left-0 w-full h-[1px]"></div>
    </div>

    <nav class="mx-auto flex max-w-[85rem] items-center justify-between p-6 lg:px-8">
      <div class="flex items-center flex-1">
        <router-link to="/" class="-m-1.5 p-1.5">
          <span class="sr-only">Logotype Thomas</span>
          <img class="h-8 w-auto" :src="logoImage" alt="Logo" />
        </router-link>
      </div>

      <div class="hidden lg:flex lg:gap-x-12 uppercase flex-2 justify-center">
        <router-link to="/projects" class="text-md transition duration-300 scale-105"
          :class="[$route.path === '/projects' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.projects') }}
        </router-link>

        <router-link to="/skills" class="text-md transition duration-300 scale-105"
          :class="[$route.path === '/skills' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.skills') }}
        </router-link>

        <Popover class="relative">
          <PopoverButton
            class="text-md transition duration-300 scale-105 examen-link flex items-center focus:outline-none focus:ring-0">
            <span class="examen-border-outer"></span>
            <span class="uppercase text-white">{{ $t('header.reviews') }}</span>
            <ChevronDownIcon class="ml-1 h-5 w-5 text-white transition-transform duration-300" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div
                class="w-[60vh] flex-auto overflow-hidden rounded-3xl bg-[#0F0F0FFF] text-sm/6 shadow-lg ring-1 ring-black">
                <div class="p-4">
                  <div v-for="item in sousMenu" :key="item.name"
                    class="group relative flex gap-x-6 rounded-lg p-4 transition duration-300 hover:bg-[#1A1A1AFF] hover:scale-105">
                    <div
                      class="mt-1 flex size-11 flex-none items-center justify-center rounded-lg bg-[#0D0D0DFF] group-hover:bg-indigo-600 transition-colors duration-300">
                      <component :is="item.icon"
                        class="size-6 text-indigo-400 group-hover:text-white transition-colors duration-300"
                        aria-hidden="true" />
                    </div>
                    <div>
                      <router-link :to="item.route"
                        class="text-white group-hover:text-indigo-400 transition-colors duration-300">
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </router-link>
                      <p
                        class="mt-1 text-gray-200 group-hover:text-gray-400 transition-colors duration-300 normal-case">
                        {{ item.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <router-link to="/contact" class="text-md transition duration-300 scale-105"
          :class="[$route.path === '/contact' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.contact') }}
        </router-link>
      </div>

      <div class="hidden lg:flex flex-1 justify-end items-center space-x-6">
        <a href="https://github.com/Swellowwh" target="_blank" rel="noopener noreferrer"
          class="text-md text-white p-2 rounded-xl rotate-2 hover:-rotate-2 duration-500 bg-sky-600 flex items-center">
          <svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <path
              d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22">
            </path>
          </svg>
          GitHub
        </a>

        <div class="relative language-menu">
          <button @click="toggleLanguageMenu"
            class="flex items-center justify-center p-1 rounded-full transition-all duration-300">
            <img :src="currentFlag" alt="Current Language" class="w-6 h-6 rounded-full shadow-sm object-cover" />
          </button>

          <transition name="fade">
            <div v-show="showLanguageMenu"
              class="absolute mt-2 right-[-5px] bg-[#1A1A1AFF] rounded-xl shadow-xl border border-gray-700 overflow-hidden min-w-max"
              style="z-index: 50;">
              <ul>
                <li v-for="(flag, key, index) in availableFlags" :key="key" @click="changeLanguage(key)"
                  class="flex items-center justify-center p-2 cursor-pointer transition-all duration-300">
                  <img :src="flag" alt=""
                    class="w-6 h-6 rounded-full shadow-sm hover:scale-110 transition-transform duration-300 object-cover" />
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>

      <div class="flex lg:hidden items-center space-x-4">
        <div class="relative language-menu">
          <button @click="toggleLanguageMenu" class="flex items-center focus:outline-none">
            <img :src="currentFlag" alt="Current Language" class="w-6 h-6" />
          </button>

          <transition name="fade">
            <div v-show="showLanguageMenu"
              class="absolute mt-2 right-[-8px] bg-[#1A1A1AFF] rounded-xl shadow-xl border border-gray-700 overflow-hidden min-w-max"
              style="z-index: 50;">
              <ul>
                <li v-for="(flag, key, index) in availableFlags" :key="key" @click="changeLanguage(key)"
                  class="flex items-center justify-center p-2 cursor-pointer transition-all duration-300">
                  <img :src="flag" alt=""
                    class="w-6 h-6 rounded-full shadow-sm hover:scale-110 transition-transform duration-300 object-cover" />
                </li>
              </ul>
            </div>
          </transition>
        </div>

        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
          @click="toggleMobileMenu">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </nav>

    <div v-if="mobileMenuOpen"
      class="lg:hidden absolute top-[80px] bg-[#0D0D0DFF] inset-x-0 backdrop-blur-md p-6 rounded-b-xl shadow-xl transition-all duration-300 border-b border-indigo-800">
      <div class="flex flex-col gap-y-6">
        <router-link to="/projects" @click="mobileMenuOpen = false" class="text-md transition duration-300"
          :class="[$route.path === '/projects' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.projects') }}
        </router-link>
        <router-link to="/skills" @click="mobileMenuOpen = false" class="text-md transition duration-300"
          :class="[$route.path === '/skills' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.skills') }}
        </router-link>

        <div class="relative">
          <button @click="toggleExamenMobileMenu"
            class="w-full text-left text-md transition duration-300 scale-105 examen-link"
            :class="[isExamenActive ? 'text-sky-400 ' : 'text-white']">
            <span class="examen-border-outer"></span>
            <div class="flex items-center">
              <span class="uppercase">{{ $t('header.reviews') }}</span>
              <ChevronDownIcon
                :class="['h-5 w-5 ml-1 transition-transform duration-300', showExamenMobileMenu ? 'rotate-180' : '']"
                aria-hidden="true" />
            </div>
          </button>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <div v-if="showExamenMobileMenu" class="mt-8 ml-2 pl-4 space-y-2 border-l-2 border-indigo-800">
              <div v-for="item in sousMenu" :key="item.name" class="group py-3 transition duration-300">
                <router-link :to="item.route" @click="closeMobileMenus"
                  class="flex items-center text-white hover:text-indigo-400 transition-colors duration-300">
                  <component :is="item.icon"
                    class="h-5 w-5 mr-2 text-indigo-400 group-hover:text-indigo-300 transition-colors duration-300"
                    aria-hidden="true" />
                  <div>
                    <div class="font-medium">{{ item.name }}</div>
                    <p class="mt-1 text-xs text-gray-400 group-hover:text-gray-300 transition-colors duration-300">
                      {{ item.description }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </transition>
        </div>

        <router-link to="/contact" @click="mobileMenuOpen = false" class="text-md transition duration-300"
          :class="[$route.path === '/contact' ? 'text-sky-400 ' : 'text-white hover:text-sky-400']">
          {{ $t('header.contact') }}
        </router-link>
        <a href="https://github.com/Swellowwh" target="_blank" rel="noopener noreferrer"
          class="text-md text-white p-2 rounded-xl hover:-rotate-2 bg-sky-600 duration-500 flex items-center">
          <svg class="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <path
              d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22">
            </path>
          </svg>
          GitHub
        </a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import {
  BanknotesIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ShoppingCartIcon,
  BuildingOfficeIcon,
  ClipboardDocumentIcon,
} from '@heroicons/vue/24/outline'
import { ref, onMounted, computed, provide } from 'vue';
import { Bars3Icon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { web_version } from '../../../version.js';

const $route = useRoute();
const { t, locale } = useI18n();

const sousMenu = computed(() => [
  { name: t('header.subMenu.gsb_web.title'), description: t('header.subMenu.gsb_web.description'), route: '/examens/gsb-web', icon: BanknotesIcon },
  { name: t('header.subMenu.gsb_vb.title'), description: t('header.subMenu.gsb_vb.description'), route: '/examens/gsb-vb', icon: BriefcaseIcon },
  { name: t('header.subMenu.nutsbetting.title'), description: t('header.subMenu.nutsbetting.description'), route: '/examens/nutsbetting', icon: ChartBarIcon },
  { name: t('header.subMenu.betcloud.title'), description: t('header.subMenu.betcloud.description'), route: '/examens/betcloud', icon: ShoppingCartIcon },
  { name: t('header.subMenu.oxyledger_web.title'), description: t('header.subMenu.oxyledger_web.description'), route: '/examens/oxyledger-wordpress', icon: BuildingOfficeIcon },
  { name: t('header.subMenu.oxyledger_module.title'), description: t('header.subMenu.oxyledger_module.description'), route: '/examens/oxyledger-module', icon: ClipboardDocumentIcon },
]);

const scrolled = ref(false);
const scrolledred = ref(false);
onMounted(() => {
  window.addEventListener('scroll', () => {
    scrolled.value = window.scrollY > 200;
    scrolledred.value = window.scrollY > 300;
  });
});

// Gestion de la bannière
const bannerClosed = ref(localStorage.getItem('bannerClosed') === 'true');
const closeBanner = () => {
  bannerClosed.value = true;
  localStorage.setItem('bannerClosed', 'true');
};

// Logo (Suppression de la partie thème sombre)
import logoImage from '@/assets/ThomasS.png';

// Sélecteur de langue
import franceFlag from '@/assets/la-france.png';
import enFlag from '@/assets/royaume-uni.png';

const flags = { en: enFlag, fr: franceFlag };
const languages = { en: 'English', fr: 'Français' };

// État actuel de la langue
const language = ref(localStorage.getItem('language') || 'fr'); // Valeur par défaut 'fr'

// Fonction pour changer de langue
const changeLanguage = (lang) => {
  language.value = lang;
  locale.value = lang;
  localStorage.setItem('language', lang);
  showLanguageMenu.value = false;
};

// Appliquer la langue au chargement
onMounted(() => {
  locale.value = language.value;
});

// Gestion du drapeau actuel
const currentFlag = computed(() => flags[language.value] || flags.fr);

// Filtrer les drapeaux disponibles pour exclure la langue active
const availableFlags = computed(() => {
  const filteredFlags = {};
  for (const key in flags) {
    if (key !== language.value) {
      filteredFlags[key] = flags[key];
    }
  }
  return filteredFlags;
});

// Gestion du menu de langue
const showLanguageMenu = ref(false);
const toggleLanguageMenu = () => {
  showLanguageMenu.value = !showLanguageMenu.value;
};

// Gestion du menu mobile
const mobileMenuOpen = ref(false);
const toggleMobileMenu = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value;
};

// Nouveau: Gestion du sous-menu Examen pour mobile
const showExamenMobileMenu = ref(false);
const toggleExamenMobileMenu = () => {
  showExamenMobileMenu.value = !showExamenMobileMenu.value;
};

// Vérifier si une route active fait partie des routes d'examen
const isExamenActive = computed(() => {
  return $route.path.startsWith('/examens/') || $route.path === '/examens';
});

// Fermer tous les menus mobiles
const closeMobileMenus = () => {
  mobileMenuOpen.value = false;
  showExamenMobileMenu.value = false;
};

// Fermer le menu des langues quand on clique ailleurs
onMounted(() => {
  document.addEventListener('click', (event) => {
    // Vérifier si le clic est à l'intérieur du menu ou sur le bouton de langue
    const isClickInsideMenu = event.target.closest('.language-menu');
    const isLanguageButton = event.target.closest('button[class*="language-toggle"]') ||
      event.target.closest('button + .language-menu');

    if (!isClickInsideMenu && !isLanguageButton && showLanguageMenu.value) {
      showLanguageMenu.value = false;
    }
  });
});

provide('language', language);
</script>

<style scoped>
.language-menu {
  position: relative;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.gradient-animation {
  background: linear-gradient(90deg, transparent, #0ea5e9, #6366f1, #8b5cf6, #0ea5e9, transparent);
  background-size: 400% 100%;
  animation: gradientFlow 3s ease infinite;
}

button:focus {
  outline: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Bordures animées pour EXAMEN */
@keyframes multi-color-orbit {

  0%,
  100% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }
}

.examen-link {
  position: relative;
  display: inline-block;
  width: max-content;
  border-radius: 0.5rem;
  overflow: visible;
}

.examen-border-outer {
  position: absolute;
  top: -8px;
  left: -12px;
  right: -12px;
  bottom: -8px;
  border-radius: 0.6rem;
  z-index: -1;

  /* Base de la bordure */
  background: transparent;

  /* Masque pour créer l'effet de bordure */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;

  /* Épaisseur de la bordure */
  padding: 3px;
}

/* Pseudo-élément pour les points lumineux qui tournent */
.examen-border-outer::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;

  /* Dégradé avec plusieurs points lumineux de différentes couleurs */
  background:
    linear-gradient(45deg,
      rgba(20, 20, 20, 0.4),
      /* Espace discret */
      rgba(14, 165, 233, 1),
      /* Point bleu #0ea5e9 */
      rgba(20, 20, 20, 0.4),
      /* Espace discret */
      rgba(99, 102, 241, 1),
      /* Point indigo #6366f1 */
      rgba(20, 20, 20, 0.4),
      /* Espace discret */
      rgba(139, 92, 246, 1),
      /* Point violet #8b5cf6 */
      rgba(20, 20, 20, 0.4),
      /* Espace discret */
      rgba(236, 72, 153, 1),
      /* Point rose #ec4899 */
      rgba(20, 20, 20, 0.4)
      /* Espace discret */
    );

  /* Taille optimisée pour l'animation */
  background-size: 900% 900%;

  /* Animation qui fait tourner les points lumineux autour du périmètre */
  animation: multi-color-orbit 8s linear infinite;
}
</style>