import { createI18n } from 'vue-i18n';
import fr from './translation/translation_fr';
import en from './translation/translation_en';

const messages = {
  fr,
  en
};

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'fr', 
  fallbackLocale: 'en',
  messages,
});

export default i18n;