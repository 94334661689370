const en = {
    // 1. Common interface elements (header, footer)
    header: {
        "projects": "Projects",
        "skills": "Skills",
        "reviews": "Reviews",
        "contact": "Contact",
        "update_info": "New version of my website",
        subMenu: {
            "gsb_web": {
                "title": "Expense Management",
                "description": "School project - Web application"
            },
            "gsb_vb": {
                "title": "Visit Management",
                "description": "School project - Desktop application"
            },
            "nutsbetting": {
                "title": "Bankroll Management and Statistics Tool",
                "description": "Web application"
            },
            "betcloud": {
                "title": "Sports Betting Advice E-commerce Site",
                "description": "E-commerce platform"
            },
            "oxyledger_web": {
                "title": "Oxyledger Company Showcase Website",
                "description": "Showcase website"
            },
            "oxyledger_module": {
                "title": "Operating Room Mapping Tool",
                "description": "Web application"
            }
        },
    },
    footer: {
        "rights": "All rights reserved.",
        "projects": "Projects",
        "skills": "Skills",
        "contact": "Contact",
    },

    // 2. Metadata and router
    meta: {
        "welcome": "Portfolio - Thomas",
        "projects": "Projects - Thomas",
        "skills": "Skills - Thomas",
        "contact": "Contact - Thomas",
        "learning": "Learn - Thomas",
        "reviews": "Reviews - Thomas",
        "notFound": "Page Not Found - Thomas",
        // New entries for projects and exams
        "gsb-web": "GSB Web Project - Thomas",
        "gsb-vb": "GSB Visual Basic Project - Thomas",
        "nutsbetting": "NutsBetting Project - Thomas",
        "betcloud": "BetCloud Project - Thomas",
        "oxyledger-wordpress": "Oxyledger WordPress Project - Thomas",
        "oxyledger-module": "Oxyledger Module - Thomas"
    },
    notFound: {
        "message": "Page not found",
        "description": "Sorry, we couldn't find the page you're looking for.",
        "backToHome": "Go back home",
        "contactSupport": "Contact support"
    },

    // 3. Specific pages
    // 3.1 Home page
    home: {
        title: {
            "first": "Designing today",
            "second": "the solutions of tomorrow",
            "introduction": "As a student in software development and self-employed entrepreneur, I combine academic learning and professional experience to design modern digital solutions. Through this portfolio, discover my commitment to excellence, my creativity, and my ability to transform your ideas into successful projects, combining performance and elegance.",
            "alternance": "Actively seeking: Apprenticeship contract in the Brest-Rennes area",
        },
        aboutMe: {
            "name": "STINDEL Thomas",
            "about_one": "Passionate about software development and studying computer science, I strive every day to transform my ideas into reality.",
            "about_two": "My journey has allowed me to develop strong skills in programming and designing innovative digital solutions.",
            "button": "Software Developer",
        },
        project: {
            "postTitle": "Discover my latest achievements",
            "title": "Discover my projects",
            "description": "I focus on creating innovative solutions where technology and creativity meet to deliver exceptional user experiences and solve concrete problems.",
            "button": "Explore my projects",
        }
    },

    // 3.2 Projects page
    projects: {
        title: {
            "first": "List of all my",
            "second": "projects gathered",
            "description": "Discover my journey through my professional and personal experiences.",
        },
        table: {
            title: {
                "project": "Project",
                "tech": "Technologies used",
                "contributor": "Contributor",
                "explore": "Explore",
            },
            table_data: {
                "0": {
                    name: 'NutsBetting',
                    category: 'Bankroll management and statistics tool',
                },
                "1": {
                    name: "Operating room schematization tool",
                    category: 'Web application',
                },
                "2": {
                    name: 'Expense management',
                    category: 'School project - Web application',
                },
                "3": {
                    name: 'Visit management',
                    category: 'School project - Desktop application',
                },
                "4": {
                    name: "Oxyledger company showcase website",
                    category: 'Showcase website',
                },
                "5": {
                    name: 'Sports betting advice e-commerce website',
                    category: 'E-commerce platform',
                }
            },
            button: "Explore the project"
        },
        contact: {
            title: "Contact me",
            description: "Do you have a project in mind? I am available to discuss its implementation.",
            button: "Contact me by email",
        },
    },

    // 3.3 Skills page
    skills: {
        title: {
            "discover": "Discover ",
            "skills": "my skills ",
            "expertise": " and my expertise"
        },
        "description": "Discover all my technical and creative skills, acquired through my professional and academic experiences. From designing modern interfaces to optimizing backend performance, and integrating innovative solutions, each technology I master allows me to transform ideas into concrete projects. Scroll down to explore my expertise and practices.",
        categories: {
            "frontendDev": "Frontend Development",
            "backendDev": "Backend Development",
            "designUX": "Design & User Experience",
            "database": "Database & Storage",
            "systems": "Systems & Hosting",
            "webMobileDev": "Web & Mobile Development",
            "cssFramework": "Modern CSS Frameworks",
            "stateManagement": "Global State Management",
            "wordpressDev": "WordPress Development",
            "apiTesting": "API Testing & Development",
            "webServer": "Web Server & Hosting",
            "webSecurity": "Web Security & Performance",
            "vsCode": "Development Environment",
            "typescript": "TypeScript Development",
            "paymentIntegration": "Online Payment Integration",
            "projectManagement": "Project Management with ClickUp"
        },
        descriptions: {
            "frontendVue": "Expertise in developing dynamic and high-performance user interfaces with Vue.js and React.",
            "backendLaravel": "Building robust and scalable backend architectures using Laravel and Node.js.",
            "designFigma": "Creating intuitive and modern UI designs with Figma and Adobe XD.",
            "databaseMySQL": "Optimizing and managing relational databases with MySQL and PostgreSQL.",
            "systemsGitLab": "Deploying and managing servers with GitLab CI/CD and Docker.",
            "reactNative": "Developing high-performance mobile applications with React Native.",
            "tailwindCSS": "Fast and flexible UI development using TailwindCSS and Bootstrap.",
            "pinia": "Advanced state management with Pinia and Redux for better application control.",
            "wordpress": "Building dynamic and high-performance websites using WordPress and Elementor.",
            "java": "Developing robust and scalable applications with Java and Spring Boot.",
            "bootstrap": "Efficient styling and responsive designs with Bootstrap.",
            "postman": "Automating and testing APIs with Postman and GraphQL.",
            "apache": "Hosting and managing applications with Apache and Nginx for optimized performance.",
            "cloudflare": "Enhancing security and web performance with Cloudflare DDoS protection and caching.",
            "vsCode": "Optimizing and customizing the development workflow with VS Code.",
            "typescript": "Developing structured and secure applications using TypeScript on Vue.js and Node.js.",
            "paymentIntegration": "Integrating secure online payment solutions with Stripe and PayPal.",
            "projectManagement": "Task tracking and organization using ClickUp for effective project management."
        },
        "skillLevel": "Good mastery and effective application"
    },

    // 3.4 Learning page
    learning: {
        "title": "My learning resources",
        "description": "This section compiles the various resources and tutorials I use to improve my skills and stay up-to-date with the latest technologies.",
        categories: {
            "tutorials": "Tutorials followed",
            "courses": "Online courses",
            "resources": "Useful resources"
        }
    },

    // 3.5 Contact page
    contact: {
        "title": "Let's get in touch!",
        "description": "If you'd like to contact me for professional opportunities or collaborations, you can find me on LinkedIn and GitHub. Feel free to connect with me through these platforms."
    },

    // 3.6 page projects
    specific_projects: {

        gsb_web: {
            section_1: {
                title: "Simplified Professional Expense Management",
                description: "A modern web application allowing medical representatives to efficiently manage their professional expense reports. Track and get reimbursed for your accommodation, meal, and mileage expenses with ease.",
            },
            section_2: {
                title: "Under the Hood",
            },
            section_3: {
                title: "Complete Expense Management Solution",
                description: "Our application facilitates expense report management for healthcare professionals on the move, with a comprehensive system for entering, validating, and reimbursing professional expenses.",
                subdescription: {
                    about_one: {
                        title: "For Medical Representatives",
                        description: "Easily submit your accommodation, meal, and mileage expenses with supporting documents from any device and track the status of your reimbursements."
                    },
                    about_two: {
                        title: "For Accountants",
                        description: "Access a dedicated dashboard to review, validate, and process reimbursement requests efficiently and transparently."
                    },
                    about_three: {
                        title: "For Administrators",
                        description: "Manage user accounts, recruit new employees from created accounts, and oversee the entire reimbursement process."
                    },
                },
            },
            section_4: {
                title: "About the Project",
                description: "A visit report management application for GSB medical representatives, allowing activity tracking and business process optimization.",
                button: "Explore the code on GitHub",
            },
        },

        gsb_vb: {
            section_1: {
                title: "Medical Report Management",
                description: "An application allowing medical representatives to create, modify, and delete patient visit reports, with an interface adapted to different types of users.",
            },
            section_2: {
                title: "Internal Mechanics",
            },
            section_3: {
                title: "Advanced Medical Report Management",
                description: "The GSB project offers an application developed with Visual Studio allowing medical representatives to create and manage their visit reports, with an intuitive interface and features adapted to the pharmaceutical sector.",
                subDescription: {
                    one: "Windows Forms Application interface developed with Visual Basic and Windows Forms offering a smooth and intuitive user experience for medical representatives, with an ergonomic design adapted to the pharmaceutical sector's needs.",
                    two: "Complete report management system allowing creation, modification, consultation, and deletion of visit reports, with the possibility to add detailed notes and track the history of interactions with each doctor.",
                    three: "Secure database with client-heavy architecture connected to a robust database, ensuring information persistence and allowing advanced searches by date, practitioner, or visit type.",
                }
            },
        },

        nutsbetting: {
            section_1: {
                subtitle: "Statistics and Management Service",
                title: "The NutsBetting Tool",
                description: "A modern application allowing sports bettors to efficiently manage their bets and analyze their performance through detailed statistics. Developed entirely by myself, this solution represents a year and a half of work combining technical learning and continuous development.",
            },
            section_2: {
                title: "The Anatomy of the Project",
            },
            section_3: {
                subtitle: "Intuitive Interface",
                title: "The Art of User Experience",
                description: "Our user-centered approach combines elegant design and accessible features. Each element is designed to offer smooth and instinctive navigation, reducing the learning curve and maximizing efficiency. From carefully selected colors to optimized user journeys, we create interfaces that both seduce and simplify.",
            },
            section_4: {
                subtitle: "GitLab CI/CD",
                title: "An Optimized Workflow",
                description: {
                    one: "The project uses a rigorous versioning system with documentation of changes and semantic tags (v1.0.0, v1.1.0) to clearly identify versions. Development branches isolate new features until validation, ensuring the stability of the production code.",
                    two: "This workflow includes dependency management and detailed changelogs for each release. Integration with the CI/CD pipeline allows triggering automated tests with each commit and activating specific deployments via version tags, thus ensuring quality and reliability.",
                },
            },
            section_5: {
                title: "About NutsBetting",
                description: "A complete application dedicated to sports bettors, offering statistical analysis tools and bet management. Developed with passion, it allows you to optimize your strategies and track your performance in real-time.",
                button: "Discover NutsBetting.com",
            },
        },

        betcloud: {
            section_1: {
                subtitle: "Sports Betting Service",
                title: "BetCloud E-Commerce",
                description: "This WordPress platform was designed to offer sports betting advice. The objective integrated a system of sports analyses and predictions to help users optimize their bets. The project developed a subscription model with WooCommerce allowing management of different levels of access to recommendations and premium content.",
            },
            section_2: {
                description_one: "Total accounts created",
                description_two: "Subscriptions that were active",
                description_three: "Development time elapsed",
                time: "months",
            },
            section_3: {
                title: "Behind the Scenes",
            },
            section_4: {
                subtitle: "Depth",
                title: "At the Heart of BetCloud",
                description: "With the goal of deepening my knowledge and developing my skills, this project allowed me to push my limits by creating a self-employed business. I designed a website from A to Z without prior expertise in the field, thus taking on a challenge that was both technical and entrepreneurial!",
                subDescription: {
                    one: {
                        name: "Customized Subscriptions",
                        description: "WordPress platform optimized to manage different types of sports betting advice subscriptions. Each user accesses a personalized dashboard according to their plan.",
                    },
                    two: {
                        name: "Secure Payments",
                        description: "Robust payment infrastructure integrating multiple gateways for smooth and secure transactions. Automated management of subscription renewals.",
                    },
                },
            },
            section_5: {
                title: "BetCloud Evolves into NutsBetting",
                description_one: "I am delighted to announce that my BetCloud project is no longer active and has been replaced by NutsBetting. This transition marks the culmination of a superb development experience that allowed me to refine my vision and significantly improve my offering.",
                description_two: " NutsBetting will remain in production in the future, benefiting from all the lessons I learned during the development of BetCloud. NutsBetting is a complete application I created for sports bettors, offering advanced statistical analysis and bet management tools. Developed with passion, it allows you to optimize your strategies and track your performance in real-time.",
                button: "Discover the Web Application",
            },
        },

        oxyledger_web: {
            section_1: {
                subtitle: "Intern at Oxyledger",
                title: "Showcase Website",
                description: "Oxyledger develops innovative solutions to ensure the traceability of medical devices in healthcare establishments. Through its advanced technologies, the company facilitates the management of implants and medical devices, guaranteeing safety and compliance. Discover an intuitive platform that optimizes hospital processes and improves patient care.",
            },
            section_2: {
                title: "The Anatomy of the Project",
            },
            section_3: {
                subtitle: "Custom Design",
                title: "A Coherent Visual Identity",
                description: "I developed an interface that perfectly reflects the company's identity. Each graphic element was meticulously designed to align with its values and vision. My approach prioritized the balance between attractive aesthetics and intuitive functionality, thus creating a distinctive and memorable user experience. Positive user feedback confirmed the relevance of my design choices and the added value for the brand.",
            },
            section_4: {
                title: "Oxyledger Project",
                description: "This WordPress project is fully functional and accessible online. You can now visit the Oxyledger.com website to explore the interface I developed from A to Z. The custom architecture and tailored features are fully operational, offering an optimal user experience. Feel free to browse the site to discover my work in a real-life situation.",
                button: "See the Site in Action",
            },
        },

        oxyledger_module: {
            section_1: {
                subtitle: "Intern at Oxyledger",
                title: "Creation of a Drawing Tool",
                description: {
                    one: "Oxyledger develops innovative solutions to ensure the traceability of medical devices in healthcare establishments. Through its advanced technologies, the company facilitates the management of implants and medical devices, guaranteeing safety and compliance.",
                    two: "During my internship at Oxyledger, I had the opportunity to design and develop an innovative tool for modeling operating rooms before procedures. This tool, directly linked to the patient's file, facilitates the organization and optimization of the room according to the surgeon's specific needs. It aims to ensure efficient and safe preparation, thereby improving the operative process.",
                },
            },
            section_2: {
                title: "The Anatomy of the Project",
            },
            section_3: {
                subtitle: "Room for Imagination",
                title: "A Custom Schematic Tool",
                description: {
                    one: "I developed a custom schematic tool responding to the constraints and needs defined by the company before my internship. Designed to optimize the organization of operating rooms based on patient files, this intuitive tool facilitates the preparation of surgical interventions and improves the efficiency of medical staff. Each graphic and functional element was designed to align with the company's identity, while offering a smooth and ergonomic user experience.",
                    two: "This project, entirely created by me, is designed to evolve and adapt to the company's future needs. It represents a solid foundation that can be enriched with new features to further optimize the organization of medical spaces.",
                },
            },
            section_4: {
                title: "Module for Medical Establishments",
                description: "I designed and developed a custom module for medical establishments. For reasons of confidentiality and data sensitivity, access to this project is restricted. This system allows optimized management of internal processes and offers an interface adapted to the specific needs of medical staff. The architecture was designed to guarantee security, confidentiality, and ease of use in a demanding professional environment.",
            },
        },
    },
};

export default en;